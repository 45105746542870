import React from 'react';
import Codepen from './Codepen';
import Github from './Github';
import Linkedin from './Linkedin';
import Instagram from './Instagram';
import Twitter from './Twitter';

const SocialIcons: React.FC<{ name: string }> = ({ name }) => {
  switch (name) {
    case 'Codepen':
      return <Codepen />;
    case 'Github':
      return <Github />;
    case 'Instagram':
      return <Instagram />;
    case 'Linkedin':
      return <Linkedin />;
    case 'Twitter':
      return <Twitter />;
    default:
      return <></>;
  }
};

export default SocialIcons;
