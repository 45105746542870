import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Header.module.scss';
import { Burger, Close } from '../../core/Icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from '../../core/Button';
import { useScrollDirection } from '../../../hooks';

const Header: React.FC = () => {
  const locationHook = useLocation();
  const [stickyHeader, setStickyHeader] = useState(false);
  const [active, setActive] = useState(false);
  const scrollDirection = useScrollDirection('down');
  useEffect(() => {
    const isSticky = (): void => {
      window.scrollY >= 50 && scrollDirection === 'up' ? setStickyHeader(true) : setStickyHeader(false);
    };
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, [scrollDirection]);
  useEffect(() => {
    setActive(false);
    window.scrollTo(0, 0);
  }, [locationHook]);
  const showMenu = (): void => {
    setActive(!active);
  };
  return (
    <header className={cx({
      [styles.isSticky]: stickyHeader
    })}>
      <nav className={cx(styles.nav, styles.navDefault)}>
        <div className="container px-16 flex flex-wrap items-center mx-auto">
          <Link to="/" className={cx('flex items-center', styles.navbarBrand)}>
            <img src={'/logo.svg'} alt={'VP'} className={cx('mr-16', styles.navbarBrandLogo)}/>
          </Link>
          <div
            className={cx('w-full md:visible md:w-auto ml-auto', styles.navCollapse, {
              [styles.active]: active
              // [styles.invisible]: !active
            })}
            id="navbar-default">
            <ul className="flex flex-col md:flex-row md:space-x-16">
              <li className={styles.delay1}>
                <HashLink to={'#about'} smooth
                         className={'block'}>About</HashLink>
              </li>
              <li className={styles.delay2}>
                <HashLink to={'#jobs'} smooth
                         className={'block'}>Experience</HashLink>
              </li>
             {/*  <li className={styles.delay3}>
                <HashLink to={'#works'} smooth
                         className={'block'}>Work</HashLink>
              </li> */}
              <li className={styles.delay4}>
                <HashLink to={'#contact'} smooth
                          className={'block'}>Contact</HashLink>
              </li>
            </ul>
          </div>
          <Button href={'https://www.linkedin.com/in/vahanpetrosyan/'} target={'_blank'} className={'ml-auto md:ml-36'} color={'primary'} size={'small'}>Resume</Button>
          <button data-collapse-toggle="navbar-default" type="button"
                  className={cx('inline-flex items-center md:hidden ml-16', styles.navbarToggle)}
                  aria-controls="navbar-default" aria-expanded="false"
                  onClick={showMenu}>
            <span className="sr-only">Open main menu</span>
            {active && <Close/>}
            {!active && <Burger/>}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
