import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from '../core/Loader';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Social from './Social/Social';
import Email from './Email/Email';

const MainLayout: React.FC = () => {
  const isHome = location.pathname === '/';
  const [isLoading, setIsLoading] = useState(isHome);
  const handleExternalLinks = (): void => {
    const allLinks = Array.from(document.querySelectorAll('a'));
    if (allLinks.length > 0) {
      allLinks.forEach(link => {
        if (link.host !== window.location.host) {
          link.setAttribute('rel', 'noopener noreferrer');
          link.setAttribute('target', '_blank');
        }
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (location.hash) {
      const id = location.hash.substring(1); // location.hash without the '#'
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView();
          el.focus();
        }
      }, 0);
    }

    handleExternalLinks();
  }, [isLoading]);

  return (
        <>
          {isLoading && isHome
            ? (
                <Loader finishLoading={() => setIsLoading(false)} />
              )
            : (
              <>
                {/* <SignInContainer/>
                <SuccessModal icon={'download'} title={'Your download is on its way'}
                              description={'Thank you for your purchase. We will process your order and send the results to your email address within 5min'}/> */}
                <Header />
                <Outlet/>
                <Footer />
                <Social />
                <Email />
              </>
              )
          }
        </>
  );
};

export default MainLayout;
