import React, { type ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '../core/Button';
const NotFound: React.FC = (): ReactElement => {
  return (
    <div className={'not-found-page'}>
      <div className={'design-elements'}>
      </div>
      <div className={'container min-h-[75vh] flex items-center'}>
        <div className={'row justify-center'}>
          <div className={'col-12 lg:col-8 text-center'}>
            <h1 className={'mt-40 mb-32 lg:mb-32'}>Error 404 {/* <br/>  <span className={'text-light'}>Page not found</span> */} </h1>
            <p className={'h3 text-light mb-32'}>
              Oops! Looks like you’ve stumbled upon a digital rabbit hole. This mystical 404 realm is where lost pages go to play hide and seek. While we conjure up a digital map to guide you back, why not indulge in a moment of wonder? Embrace the unexpected and let the adventure begin!
            </p>
            <Link to={'/'}>
              <Button color={'secondary'}>Go to Homepage</Button>
            </Link>
          </div>
          <div className={'col-12 lg:col-4 lg:push-1'}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
