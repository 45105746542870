import styles from './Contact.module.scss';
import React from 'react';
import Button from '../../core/Button';

const Contact: React.FC = () => {
  return (
    <section className={styles.contact} id={'contact'}>
      <div className={'container'}>
        <div className={'row justify-center'}>
          <div className={'col-12 lg:col-6'}>
            <p className={styles.contactTitle}> 03. What’s Next?</p>
            <h3 className={styles.contactBigTitle}>Get In Touch</h3>
            <p className={styles.contactDescription}>Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!</p>
            <Button href={'mailto:vahan@petrosyan.tech'} size={'small'}>Contact me</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
