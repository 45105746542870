import styles from './About.module.scss';
import React from 'react';
import NumericTitle from '../../specific/NumericTitle/NumericTitle';

const About: React.FC = () => {
  return (
    <section className={styles.about} id={'about'}>
      <div className={'container'}>
        <div className={'row justify-center'}>
          <div className={'col-12 lg:col-9'}>
            <NumericTitle number={'01'}>About me</NumericTitle>
            <div className={'text'}>
              <p>Welcome to the world of web programming, where I bring digital dreams to life!
                As a web programmer, my gig revolves around crafting innovative and functional websites that captivate
                users and deliver seamless experiences.
                From building responsive layouts and implementing interactive features to optimizing performance and
                ensuring security,
                I combine my expertise in programming languages like <a href={'https://www.javascript.com/'}
                                                                        rel={'noreferrer noopener'}
                                                                        target={'_blank'}>JavaScript,</a> <a
                  href={'https://www.php.net/'} rel={'noreferrer noopener'} target={'_blank'}>PHP,</a> <a
                  href={'https://www.python.org/'} rel={'noreferrer noopener'} target={'_blank'}>Python</a> and
                frameworks like <a href={'https://react.dev'} rel={'noreferrer noopener'}
                                   target={'_blank'}>React,</a> <a href={'https://angular.io/'}
                                                                   rel={'noreferrer noopener'}
                                                                   target={'_blank'}>Angular,</a> <a
                  href={'https://angular.io/'}
                  rel={'noreferrer noopener'}
                  target={'_blank'}>Nuxt,</a> <a
                  href={'https://laravel.com/'}
                  rel={'noreferrer noopener'}
                  target={'_blank'}>Laravel</a> to create
                stunning online destinations.
                Whether it&apos;s an e-commerce site, a blog, or a custom web application,
                I&apos;m here to transform your ideas into pixel-perfect realities.
                Let&apos;s collaborate and make your online presence shine!</p>
              <p className={'mt-10'}>Here are a few technologies I’ve been working with recently:</p>
              <ul>
                <li>JavaScript (ES6+)</li>
                <li>TypeScript</li>
                <li>React</li>
                <li>Nuxt</li>
                <li>Laravel</li>
                <li>Node.js</li>
                <li>React Natives</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
