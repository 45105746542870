import styles from './Jobs.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import NumericTitle from '../../specific/NumericTitle/NumericTitle';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { usePrefersReducedMotion } from '../../../hooks';
import { KEY_CODES } from '../../../utils';
import useWindowSize from '../../../hooks/useWindowSize';

const jobsData = [
  {
    title: 'Senior Software Engineer',
    company: 'PrometAI',
    location: 'Remote',
    range: 'Jan 2023 - Present',
    url: 'https://prometai.app/',
    html: '<ul>' +
      '<li>During my tenure at this company, I developed a frontend application integrated with an AI-powered backend that generates business plan data for presentations. The application allows users to save their data and provides a preview of their business plan presentation, which can be downloaded as a PDF or exported to PPTX. Additionally, users have the flexibility to customize the content, including data, images, font sizes, and theme colors, ensuring their presentation aligns with their specific needs and preferences. The application also supports real-time updates, making the customization process intuitive and efficient.</li>' +
      '</ul>'
  },
  {
    title: 'Backend Software Engineer',
    company: 'RB',
    location: 'Remote',
    range: 'Sep 2022 - Present',
    url: 'https://odds.ru/',
    html: '<ul>' +
      '<li>During my tenure at this company, I managed real-time data reception from a data provider API. I was responsible for saving the incoming data in the product database, ensuring it was organized and prioritized according to business needs.</li>' +
      '<li>This data was then distributed to other products within the company, supporting various functionalities.</li>' +
      '<li>I designed and developed three versions of the API, enhancing its efficiency, scalability, and ease of integration for internal and external users. I also collaborated with cross-functional teams to ensure seamless integration and optimal performance across all products.</li>' +
      '</ul>'
  },
  {
    title: 'Full-stack Developer',
    company: 'MIIIND',
    location: 'Yerevan, Armenia',
    range: 'Jun 2019 - Sep 2022',
    url: 'https://miiind.co/',
    html: '<ul>' +
      '<li>During my tenure at the software company, I played a pivotal role in developing and delivering cutting-edge solutions. Collaborating with a dynamic team, I contributed to the creation of robust web applications, implementing features that elevated user experiences. </li>' +
      '<li>Leveraging my skills in frontend technologies like React.js and Angular, as well as backend proficiency in Node.js and PHP, I crafted elegant code structures and optimized performance.</li>' +
      '<li>Additionally, I actively participated in code reviews, conducted thorough testing, and provided valuable insights for continuous improvement.</li>' +
      '<li>Through my dedication and expertise, I contributed to the company\'s success in delivering high-quality software solutions to clients.</li>' +
      '</ul>'
  },
  {
    title: 'Full-stack Developer',
    company: 'Maxarts',
    location: 'Yerevan, Armenia',
    range: 'Jun 2016 - Mar 2020',
    url: 'https://mhost.am/',
    html: '<ul>' +
      '<li>As a web developer, I played a vital role in designing and implementing robust software solutions, collaborating with cross-functional teams to ensure seamless integration and user-friendly interfaces.</li>' +
      '<li>Additionally, as part of the hosting provider team, I managed and maintained servers, ensuring high availability and performance for clients\' websites and applications. From troubleshooting technical issues to optimizing server configurations, I played a crucial role in providing reliable hosting services. </li>' +
      '</ul>'
  }
];
const Jobs: React.FC = () => {
  const size = useWindowSize();
  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState<number | null>(null);
  const tabs = useRef<any>([]);
  // const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    // if (prefersReducedMotion) {
    //
    // }

    // sr.reveal(revealContainer.current, srConfig());
  }, []);

  const focusTab = (): void => {
    if (tabFocus !== null && tabs.current !== null) {
      if (tabs.current[tabFocus]) {
        // @ts-ignore
        tabs.current[tabFocus].focus();
        return;
      }
      // If we're at the end, go to the start
      if (tabFocus >= tabs.current.length) {
        setTabFocus(0);
      }
      // If we're at the start, move to the end
      if (tabFocus < 0) {
        setTabFocus(tabs.current.length - 1);
      }
    }
  };

  // Only re-run the effect if tabFocus changes
  useEffect(() => focusTab(), [tabFocus]);

  // Focus on tabs when using up & down arrow keys
  const onKeyDown = (e: any): void => {
    switch (e.key) {
      case KEY_CODES.ARROW_UP: {
        e.preventDefault();
        // @ts-ignore
        setTabFocus(tabFocus - 1);
        break;
      }

      case KEY_CODES.ARROW_DOWN: {
        e.preventDefault();
        setTabFocus(Number(tabFocus) + 1);
        break;
      }

      default: {
        break;
      }
    }
  };
  return (
    <section className={styles.jobs} id={'jobs'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12 lg:col-6 lg:push-2'}>
            <NumericTitle number={'02'}>Where I’ve Worked</NumericTitle>
            <div className={styles.tabsWrapper}>
              <div className={styles.tabsNav} role="tablist" onKeyDown={e => onKeyDown(e)}>
                {jobsData?.map((node, i) => {
                  return (
                    <button
                      key={i}
                      className={cx({
                        [styles.active]: activeTabId === i
                      })}
                      // isActive={activeTabId === i}
                      onClick={() => setActiveTabId(i)}
                      ref={el => (tabs.current[i] = el)}
                      id={`tab-${i}`}
                      role="tab"
                      // tabIndex={activeTabId === i ? '0' : '-1'}
                      aria-selected={activeTabId === i}
                      aria-controls={`panel-${i}`}>
                      <span>{node.company}</span>
                    </button>
                  );
                })}
                <div className={styles.activeLine} style={{
                  transform: size.width > 768 ? `translateY(calc(${activeTabId} * 42px))` : `translateX(calc(${activeTabId} * 120px))`
                }} />
              </div>
              <div className={styles.tabsPanel}>
                {jobsData?.map((node, i) => {
                  return (
                    <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
                      <div
                        id={`panel-${i}`}
                        role="tabpanel"
                        // tabIndex={activeTabId === i ? '0' : '-1'}
                        aria-labelledby={`tab-${i}`}
                        aria-hidden={activeTabId !== i}
                        hidden={activeTabId !== i}>
                        <h3>
                          <span>{node.title}</span>
                          <span className={styles.company}> @<a target={'_blank'} rel={'noreferrer noopenner'} href={node.url} className="inline-link">{node.company}</a></span>
                        </h3>
                        <p className={styles.range}>{node.range}</p>
                        <div className={'text'} dangerouslySetInnerHTML={{ __html: node.html }} />
                      </div>
                    </CSSTransition>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jobs;
