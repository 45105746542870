import React from 'react';
import styles from './Social.module.scss';
import SocialIcons from '../../core/Icons/Social';

const socialMedia = [
  {
    name: 'Github',
    url: 'https://github.com/vahanspetrosyan'
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/vahan.rw'
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/VahanPetrosyan6'
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/in/vahanpetrosyan/'
  },
  {
    name: 'Codepen',
    url: 'https://codepen.io/vahanpetrosyan'
  }
];
const Social: React.FC = () => {
  return (
    <div className={styles.side}>
      <div className={styles.socialList}>
        {socialMedia?.map(({ url, name }, i) => (
            <li key={i}>
              <a href={url} aria-label={name} target="_blank" rel="noreferrer">
                <SocialIcons name={name} />
              </a>
            </li>
        ))}
      </div>
    </div>
  );
};

export default Social;
