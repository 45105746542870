import React from 'react';
import styles from './Email.module.scss';

const Email: React.FC = () => {
  return (
    <div className={styles.side}>
      <div className={styles.email}>
        <a href={'mailto:vahan@petrosyan.tech'}>vahan@petrosyan.tech</a>
      </div>
    </div>
  );
};

export default Email;
