import React from 'react';
import styles from './NumericTitle.module.scss';
const NumericTitle: React.FC<{ number: string, children: string }> = ({
  number,
  children
}) => {
  return (
    <>
      <h2 className={styles.title}><span>{number}.</span> {children}</h2>
    </>
  );
};
export default NumericTitle;
