import React, { useEffect, useRef, useState } from 'react';
import styles from './Loader.module.scss';
import anime from 'animejs';
import cx from 'classnames';

const Loader: React.FC<any> = ({ finishLoading }: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const pathRef = useRef<SVGPathElement>(null);
  const animate = (): void => {
    let length;
    if (pathRef.current) {
      const path = pathRef.current;
      path.removeAttribute('stroke-dasharray');
      path.removeAttribute('stroke-dashoffset');
      length = Number(path.getTotalLength());
      path.style.strokeDasharray = String(length);
      path.style.strokeDashoffset = String(length);
    } else {
      length = 3672;
    }

    const loader = anime.timeline({
      complete: () => finishLoading()
    });

    loader
      .add({
        targets: '#logo .path',
        delay: 0,
        duration: 2000,
        easing: 'easeInOutQuart',
        strokeDashoffset: [length + 48, length + length * 0.54]
      })
      .add({
        targets: '#logo #B',
        duration: 500,
        easing: 'easeInOutQuart',
        opacity: 1
      })
      .add({
        targets: '#logo',
        delay: 0,
        duration: 300,
        easing: 'easeInOutQuart',
        opacity: 0,
        scale: 0.1
      })
      .add({
        targets: '.loader',
        duration: 200,
        easing: 'easeInOutQuart',
        opacity: 0,
        zIndex: -1
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
     <div className={cx(styles.loader, 'loader', {
       [styles.mounted]: isMounted
     })}>
       <div className={styles.logoWrapper}>
         <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="761" height="569" viewBox="0 0 761 569" fill="none">
           <title>Loader Logo</title>
           <mask xmlns="http://www.w3.org/2000/svg" id="path-1-inside-1_2_17" fill="currentColor">
             <path
               d="M217 25H736V544H217V543.729V543.202V542.669V542.132V541.59V541.043V540.491V539.935V539.373V538.807V538.236V537.661V537.08V536.495V535.905V535.311V534.712V534.108V533.5V532.887V532.269V531.647V531.02V530.389V529.753V529.113V528.469V527.819V527.166V526.508V525.845V525.179V524.507V523.832V523.152V522.468V521.779V521.086V520.389V519.688V518.982V518.272V517.558V516.84V516.118V515.391V514.66V513.925V513.187V512.444V511.697V510.945V510.19V509.431V508.668V507.901V507.13V506.355V505.576V504.793V504.006V503.215V502.421V501.622V500.82V500.014V499.204V498.391V497.574V496.752V495.928V495.099V494.267V493.431V492.592V491.748V490.902V490.051V489.197V488.34V487.479V486.614V485.746V484.875V483.999V483.121V482.239V481.354V480.465V479.572V478.677V477.778V476.876V475.97V475.061V474.149V473.233V472.314V471.392V470.467V469.539V468.607V467.672V466.734V465.793V464.849V463.902V462.952V461.998V461.042V460.082V459.12V458.154V457.186V456.214V455.24V454.262V453.282V452.299V452H192V544V569H736H761V544V25V0H736H217H192V25V92H217V25Z"
               fillRule="evenodd"
               clipRule="evenodd" />
           </mask>
           <path
             ref={pathRef}
             d="M217 25H736V544H217V543.729V543.202V542.669V542.132V541.59V541.043V540.491V539.935V539.373V538.807V538.236V537.661V537.08V536.495V535.905V535.311V534.712V534.108V533.5V532.887V532.269V531.647V531.02V530.389V529.753V529.113V528.469V527.819V527.166V526.508V525.845V525.179V524.507V523.832V523.152V522.468V521.779V521.086V520.389V519.688V518.982V518.272V517.558V516.84V516.118V515.391V514.66V513.925V513.187V512.444V511.697V510.945V510.19V509.431V508.668V507.901V507.13V506.355V505.576V504.793V504.006V503.215V502.421V501.622V500.82V500.014V499.204V498.391V497.574V496.752V495.928V495.099V494.267V493.431V492.592V491.748V490.902V490.051V489.197V488.34V487.479V486.614V485.746V484.875V483.999V483.121V482.239V481.354V480.465V479.572V478.677V477.778V476.876V475.97V475.061V474.149V473.233V472.314V471.392V470.467V469.539V468.607V467.672V466.734V465.793V464.849V463.902V462.952V461.998V461.042V460.082V459.12V458.154V457.186V456.214V455.24V454.262V453.282V452.299V452H192V544V569H736H761V544V25V0H736H217H192V25V92H217V25Z"
             fillRule="evenodd"
             clipRule="evenodd"
             stroke="currentColor"
             strokeWidth="50"
             strokeLinejoin="miter"
             strokeLinecap="square"
             className={'path'}
             mask="url(#path-1-inside-1_2_17)"
           />
           <path
             id="B"
             d="M178.656 399H94.6719L0.921875 139.82H89.0078L178.656 399ZM192.133 139.82H278.852L189.203 391.188L150.336 277.125L192.133 139.82ZM296.43 399V139.82H381.195V399H296.43ZM394.867 191.578V139.82H491.742L543.5 191.578V280.445L491.742 332.398H394.867V280.445H457.562V191.578H394.867Z"
             fill="currentColor"/>
         </svg>
       </div>
     </div>
    </>

  );
};

export default Loader;
