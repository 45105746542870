import React from 'react';
import Banner from '../../components/Home/Banner/Banner';
import About from '../../components/Home/About/About';
import Jobs from '../../components/Home/Jobs/Jobs';
import Works from '../../components/Home/Works/Works';
import WorksArchive from '../../components/Home/WorksArchive/WorksArchive';
import Contact from '../../components/Home/Contact/Contact';
// import SignInModal from '../SignIn/components/LoginModal';
// import { show } from '../../components/core/Modal';
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';

const Home: React.FC = () => {
  return (
    <>
      {/* <SignInModal isLoading={false} onSubmit={() => { console.log('ss'); }} register={() => { console.log('ss'); }} errors={false} />
      <Button onClick={() => {
        show('signInModal');
      }} size={'small'} color={'secondary'}> ALL IS OKAY </Button>

      <Button href={'https://google.com'} target={'_blank'} size={'small'} color={'secondary'}> ALL IS OKAY </Button> */}
      <Banner/>
      <About/>
      <Jobs/>
      {/* <Works/> */}
      {/* <WorksArchive /> */}
      <Contact />
    </>
  );
};
export default Home;
