import styles from './Banner.module.scss';
import Button from '../../core/Button';
import React from 'react';

const Banner: React.FC = () => {
  return (
    <section className={styles.banner} id={'home'}>
      <div className={'container'}>
        <div className={'row justify-center'}>
          <div className={'col-12 lg:col-10'}>
            <p className={styles.smallTitle}>Meet</p>
            <p className={styles.titleFirst}>Vahan  Petrosyan</p>
            <p className={styles.titleSecond}>The coding virtuoso and problem-solving extraordinaire!</p>
            <p className={styles.description}>With a keen eye for aesthetics and an insatiable passion for technology,
              I’m a full stack web developer who brings digital dreams to life.
              Armed with an arsenal of programming languages, frameworks, and a deep understanding of user experience,
              I effortlessly bridge the gap between back-end wizardry and front-end finesse.</p>
            <Button href={'mailto:vahan@petrosyan.tech'} size={'large'}>Contact me</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
