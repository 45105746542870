import React from 'react';
const Burger: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 3C1.44772 3 1 3.44772 1 4C1 4.55228 1.44772 5 2 5H22C22.5523 5 23 4.55228 23 4C23 3.44772 22.5523 3 22 3H2Z" fill="white"/>
      <path d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z" fill="white"/>
      <path d="M1 20C1 19.4477 1.44772 19 2 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20Z" fill="white"/>
    </svg>

  );
};
export default Burger;
