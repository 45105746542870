import React from 'react';
import styles from './Footer.module.scss';
import SocialIcons from '../../core/Icons/Social';

const socialMedia = [
  {
    name: 'Github',
    url: 'https://github.com/vahanspetrosyan'
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/vahan.rw'
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/VahanPetrosyan6'
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/in/vahanpetrosyan/'
  },
  {
    name: 'Codepen',
    url: 'https://codepen.io/vahanpetrosyan'
  }
];
const Footer: React.FC = () => {
  return (
    <footer>
      <div className={'container'}>
        <ul className={styles.social}>
          {socialMedia?.map(({ name, url }, i) => (
              <li key={i}>
                <a href={url} aria-label={name}>
                  <SocialIcons name={name} />
                </a>
              </li>
          ))}
        </ul>
        {/* <p className={styles.copy}>Design By Chiang, <br /> Modified & Developed by Me</p> */}
      </div>
    </footer>
  );
};

export default Footer;
